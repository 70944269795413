import React, { useState } from "react";
import {
  Typography,
  makeStyles,
} from "@material-ui/core";
import StudentPhoto from "./StudentPhoto";
import "../FormEnvio.css";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const StepFoto = (props) => {
  useStyles();
  const nextStep = props.nextStep;
  const buttons = props.buttons;
  const setData = props.setData;
  const [cropPhoto, setCropPhoto] = useState();
  const [onCroping, setOnCroping] = useState(false);

  const handleSubmit = () => {
    if (!!cropPhoto) {
      setData({ photo: cropPhoto });
      nextStep();
    }
  };

  return (
    <>
      <header>
        <div>
          <Typography color="textPrimary" variant="h2">
            Foto
          </Typography>
          <Typography color="textSecondary" gutterBottom variant="body2">
            Nos envie uma foto, o fundo precisa ser branco
          </Typography>
        </div>
      </header>
      <main>
        <div className="crop-container">
          <StudentPhoto
            setCropPhoto={setCropPhoto}
            setOnCroping={setOnCroping}
          />
        </div>
      </main>
      <footer> {!onCroping && buttons(handleSubmit)}</footer>
    </>
  );
};

export default StepFoto;
