import { Box, Typography } from "@material-ui/core";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import React, { useState } from "react";
import "../FormEnvio.css";
import StepEntregasUEP from "./entrega-retirada/StepEntregaUEP";
import StepEntregasDCEUFRJ from "./entrega-retirada/StepEntregaDCEUFRJ";
import FileInput from "../../../components/FileInput";
import AmesRetirada from "./entrega-retirada/StepAmesRetirada";

const StepComprovantes = (props) => {
  const nextStep = props.nextStep;
  const buttons = props.buttons;
  const setData = props.setData;
  const data = props.data;
  const entidade = props.entidade;

  const showUEPDialog = entidade.url === "uep";
  const showAmesSalvadorDialog = entidade.url === "ames_salvador";
  const showDceUfrjDialog = entidade.url === "dce_ufrj";

  const [pagamento, setPagamento] = useState(data?.comprovantePagamento);
  const [matricula, setMatricula] = useState(data?.comprovanteMatricula);
  const [rgFrontSide, setRgFrontSide] = useState(data?.rgFrontSide);
  const [rgBackSide, setRgBackSide] = useState(data?.rgBackSide);
  const [tipoEntrega, setTipoEntrega] = useState(
    data?.delivery ? "retirada" : "entrega"
  );
  const [delivery, setDelivery] = useState(data?.delivery);

  const handlePagamentoChange = (event) => {
    setPagamento(event.target.files[0]);
  };

  const handleMatriculaChange = (event) => {
    setMatricula(event.target.files[0]);
  };

  const handleRgFrontSideChange = (event) => {
    setRgFrontSide(event.target.files[0]);
  };

  const handleRgBackSideChange = (event) => {
    setRgBackSide(event.target.files[0]);
  };
  const handleSubmit = () => {
    if (!matricula || !pagamento) return;

    if (
      showUEPDialog &&
      (!tipoEntrega || (tipoEntrega === "retirada" && !delivery))
    )
      return;
    if (showAmesSalvadorDialog && !delivery)
      // Local de retirada obrigatório na Ames Salvador
      return;
    if (showDceUfrjDialog && (!tipoEntrega || (tipoEntrega === "retirada" && !delivery)))
      return;

    let comprovantes = {};
    comprovantes.comprovantePagamento = pagamento;
    comprovantes.comprovanteMatricula = matricula;
    comprovantes.rgFrontSide = rgFrontSide;
    comprovantes.rgBackSide = rgBackSide;
    if (delivery) {
      comprovantes.delivery = delivery;
    }

    setData({ ...comprovantes });
    nextStep();
  };

  return (
    <>
      <header>
        <div>
          <Typography color="textPrimary" variant="h2">
            Comprovantes
          </Typography>
          <Typography color="textSecondary" gutterBottom variant="body2">
            Faça upload dos comprovantes de matrícula e do pagamento :)
          </Typography>
        </div>
      </header>
      <main>
        <Box fullHeight mb={3}>
          <div>
            {showUEPDialog &&
              StepEntregasUEP({
                tipoEntrega,
                setTipoEntrega,
                delivery,
                setDelivery,
              })}
            {showAmesSalvadorDialog && <AmesRetirada delivery={delivery} setDelivery={setDelivery} />}
            {showDceUfrjDialog && <StepEntregasDCEUFRJ
              tipoEntrega={tipoEntrega}
              setTipoEntrega={setTipoEntrega}
              delivery={delivery}
              setDelivery={setDelivery}
            />}
            <FileInput
              id="upload-pagamento"
              name="upload-pagamento"
              type="file"
              accept="image/*, pdf/*"
              color="primary"
              value={pagamento}
              setValue={setPagamento}
              onChange={handlePagamentoChange}
              description={"Upload Comprovante de Pagamento da carterinha"}
              Icon={<AttachMoneyIcon />}
            />
            <FileInput
              id="upload-matricula"
              name="upload-matricula"
              type="file"
              accept="image/*, pdf/*"
              color="secondary"
              value={matricula}
              setValue={setMatricula}
              onChange={handleMatriculaChange}
              description={"Upload Comprovante de matricula"}
              Icon={<AssignmentIndIcon />}
            />
            <FileInput
              id="upload-rgFrontSide"
              name="upload-rgFrontSide"
              type="file"
              color="primary"
              accept="image/*"
              value={rgFrontSide}
              setValue={setRgFrontSide}
              onChange={handleRgFrontSideChange}
              description={"Upload do RG (Frente)"}
              Icon={<AssignmentIndIcon />}
            />
            <FileInput
              id="upload-rgBackSide"
              name="upload-rgBackSide"
              type="file"
              color="secondary"
              accept="image/*"
              value={rgBackSide}
              setValue={setRgBackSide}
              onChange={handleRgBackSideChange}
              description={"Upload do RG (Trás)"}
              Icon={<AssignmentIndIcon />}
            />
          </div>
        </Box>
      </main>
      <footer> {buttons(handleSubmit)}</footer>
    </>
  );
};

export default StepComprovantes;
