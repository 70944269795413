import { Typography } from "@mui/material";
import ValueSelector from "../../../../components/ValueSelector/ValueSelector";

const AmesRetirada = ({delivery, setDelivery}) => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        justifyItems: "center",
        width: "100%",
      }}
    >
      <div>
        <div style={{ marginTop: "10px" }}>
          <Typography color="textPrimary" variant="h5">
            <strong>Boleto/Cartão</strong> - Acesse:
            <a
              href="https://pag.ae/7-fMUXNr1"
              target="_blank"
              rel="noreferrer"
            >
              https://pag.ae/7-fMUXNr1
            </a>
          </Typography>
          <Typography color="textPrimary" variant="h5">
            Após a realização do pagamento, solicitamos que seja
            anexado o comprovante.
          </Typography>
        </div>
      </div>
      <div style={{ marginTop: "10px" }}>
        <ValueSelector
          label="Selecione o local de retirada"
          choices={[
            "SENAI / CIMATEC",
            "IFBA SSA",
            "UNEB",
            "UFBA Ondina",
            "UFBA Canela",
            "UFBA São Lázaro",
            "Feira de Santana - Centro",
            "Feira de Santana - UEFS",
            "Vitória da Conquista",
            "Jacobina",
            "UCSAL",
            "UNIFACS",
            "UNIFTC",
            "UNIJORGE",
            "ESTACIO",
            "ICEIA",
            "IFBA Camaçari",
            "IFBA Simões Filho",
            "Unime Lauro de Freitas",
            "SENAI Lauro de Freitas",
            "SESI Retiro", 
            "Sede Salvador (Rua Tingui, 11)",
          ]}
          name={"delivery"}
          onChange={(e) => setDelivery(e.target.value)}
          value={delivery}
        />
      </div>
    </div>
  );

  export default AmesRetirada;